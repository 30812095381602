<template>
    <div id="bg">
        <div class="search">
            <div class="search-box">
                <span class="search-box-title">订单号：</span>
                <el-input v-model="search.order" placeholder="请输入订单号" size="medium" clearable></el-input>
            </div>
            <div class="search-box">
                <span class="search-box-title">银商账号：</span>
                <el-input v-model="search.ys_account" placeholder="请输入银商账号" size="medium" clearable></el-input>
            </div>
            <div class="search-box">
                <span class="search-box-title">用户账号：</span>
                <el-input v-model="search.username" placeholder="请输入银商账号" size="medium" clearable></el-input>
            </div>
            <div class="search-box">
                <span class="search-box-title">时间：</span>
                <el-date-picker v-model="search.searchDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                value-format="YYYY-MM-DD"></el-date-picker>
            </div>
            <div class="search-box">
                <span class="search-box-title">状态：</span>
                <el-select v-model="search.status" filterable placeholder="全部" size="medium" clearable>
                    <el-option key="-1" label="全部" value="-1"></el-option>
                  <el-option key="0" label="待支付" value="0"></el-option>
                  <el-option key="1" label="已支付" value="1"></el-option>
                  <el-option key="2" label="已取消" value="2"></el-option>
                </el-select>
            </div>
            <div class="search-box">
                <el-button type="primary" size="medium" @click="(pager.page = 1),getList()">查询</el-button>
            </div>
        </div>
        <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
            <el-table-column prop="order" label="订单号" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="ys_uid" label="银商ID" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="ys_username" label="银商账号" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="uid" label="用户ID" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="username" label="用户账号" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="pay_type" label="充值项目" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                <span v-if="scope.row.pay_type == 0">钱包充值</span>
                <span v-if="scope.row.pay_type > 100">VIP充值</span>
              </template>
            </el-table-column>
            <el-table-column prop="type_title" label="支付类型" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="addtime" label="时间" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                <span>{{timeToDate(scope.row.addtime)}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="金额" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
                <template #default="scope">
                    <div v-if="scope.row.status == 0 ">待支付</div>
                    <div v-if="scope.row.status == 1 " :style="{color: '#33C933'}">已支付</div>
                    <div v-if="scope.row.status == 2 " :style="{color: '#F33333'}">已取消</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200px" align="center">
                <template #default="scope">
                    <span class="operation" @click="editScore(scope.row)">追分</span>
                </template>
            </el-table-column>
        </el-table>
        <page :pager="pager" @query="getList()" />

        <!-- 追分 -->
      <el-dialog custom-class="dialog" title="追分" v-model="dialogScore" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialog">
        <div class="form_box">
          <div class="form_title">余额：</div>
          <el-input v-model="dialogArr.price" placeholder="余额" size="small"></el-input>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="updateUserScore">确认修改</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { getList, updateUserScore } from "@/api/ys/payLog";
  import page from "@/components/page";
  export default {
    name: "payLog",
    components: {
        page,
    },
    data() {
      return {
        search: {
            //搜索
            order: "",
            username: "",
            ys_account: "",
            searchDate: [], //时间
            status: "-1",
        },
        pager: { total: 0, page: 1, rows: 10 },
        tableData: [], //数据
        dialogArr: {
          price: "", // 余额
        },
        dialogScore: false, // 追分
      };
    },
    computed: {},
    created() {},
    mounted() {
      this.getList();
    },
    methods: {
      timeToDate(time){
        if (time == 0){
          return "-"
        }
        return this.$common.timeToDate(time)
      },
      //获取消息列表
      getList() {
        getList([
            { key: "page", val: this.pager.page - 1 },
            { key: "row", val: this.pager.rows },
            { key: "orderNum", val: this.search.order },
            { key: "account", val: this.search.username },
            { key: "ysAccount", val: this.search.ys_account },
            { key: "begin_time", val: this.search.searchDate && this.search.searchDate.length > 0 ? this.search.searchDate[0] : "" },
            { key: "end_time",val: this.search.searchDate && this.search.searchDate.length > 0 ? this.search.searchDate[1] : "" },
            { key: "status", val: this.search.status },
        ])
          .then((res) => {
            if (res.code == 0) {
              if (this.pager.page == 1){
                this.pager.total = res.data.total;
              }
                this.tableData = res.data.list;
            } else {
                this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 追分
      editScore(row){
        this.row = row;
        this.dialogScore = true;
      },
      updateUserScore(){
        if(this.dialogArr.price < 0 ) {
          return this.$message({ message: "追分金额不能小于0", type: "error" });
        }
        this.$prompt("请输入谷歌验证码,未绑定则跳过", '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPlaceholder:"请输入谷歌验证码,未绑定则跳过",
          inputErrorMessage: '谷歌验证码只能是数字'
        }).then(({ value }) => {
          updateUserScore([
            { key: "id", val: this.row.id },
            { key: "price", val: this.dialogArr.price },
            { key: "google_code", val: value },
          ]).then((res) => {
            if (res.code == 0) {
              this.$message({ message: res.msg, type: "success" });
              this.dialogScore = false;
              this.getList();
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
              .catch((err) => {
                console.log(err);
              });
        }).catch(() => {
        });

      },

    },
  };
  </script>
  
  <style scoped>
    .form_box {
        overflow: hidden;
        margin-bottom: 18px;
    }
    .form_title {
        width: 30%;
        float: left;
        height: 32px;
        line-height: 32px;
    }
    .form_box >>> .el-input,
    .form_box >>> .el-textarea {
        width: 60%;
    }
    .form_box >>> .el-select .el-input {
        width: 100%;
    }
  </style>